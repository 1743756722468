body {
  background-color: #141414;
  color: #FFFFFF;
}

h2,
h1,
p,
ul,
span,
h3 {
  margin: 0px;
}

.mangas-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
}

.mangas-card {
  display: flex;
  flex-basis: 16.42%;
  flex-direction: column;
  text-transform: capitalize;
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  text-transform: capitalize;
  position: relative;
}

.mangas-card img {
  width: 100%;
  height: 100%;
  max-height: 350px !important;
  object-fit: cover;
  object-position: center;
}

.capitalize {
  text-transform: capitalize;
}

.img-box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.infos-box {
  position: absolute;
  overflow: hidden;
  height: 0%;
  width: 100%;
  top: 0%;
  left: 0%;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mangas-card.hovered .infos-box {
  height: 100%;
  box-sizing: border-box;
}


@media all and (max-width: 1684px) {
  .mangas-card {
    flex-basis: 24.7%;
  }
}

@media all and (max-width: 1248px) {
  .mangas-card {
    flex-basis: 33%;
  }
}

@media all and (max-width: 1024px) {
  .mangas-card {
    flex-basis: 32.8%;
  }

  .mangas-card h1 {
    font-size: 18px;
    padding-left: 10px;
  }

  .mangas-card h2 {
    font-size: 14px;
    text-align: center;
  }

  p {
    font-size: 12px
  }
}

@media all and (max-width: 634px) {
  .mangas-card {
    flex-basis: 32.8%;
  }

  .infos-box {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
  }
}

@media all and (max-width: 634px) {
  .mangas-card {
    flex-basis: 32.4%;
  }
}

@media all and (max-width: 358px) {
  .mangas-card {
    flex-basis: 32.2%;
  }
}

@media all and (max-width: 290px) {
  .mangas-card {
    flex-basis: 100%;
  }
}


/* Page Manga */

.wrapper-manga {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

}

.manga-bio {
  max-width: 1200px;
  margin: auto;
  display: flex;
  min-height: 60vh;
  box-sizing: border-box;
}


.manga-img {
  display: flex;
  flex-basis: 50%;
  max-width: 50%;
  box-sizing: border-box;
  justify-content: flex-end;
  height: 100%;

}

.manga-img img {
  max-width: 100%;
  object-fit: cover;
  object-position: right;
  max-height: 600px;
}

.manga-content {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  padding-left: 40px;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  height: auto;
}

.manga-content ul {
  list-style: none;
  padding-left: 15px;
}

.cta-chapter {
  all: unset;
  border: 2px solid white;
  padding: 4.5px 25px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  color: #141414;
  transition: 0.5s;
  max-height: 35px;
  height: 35px;
  box-sizing: border-box;
}

.cta-chapter:hover {

  text-transform: uppercase;
  background-color: #141414;
  color: white;
  cursor: pointer;
}

.manga-content .chapter-title {
  margin-bottom: 10px;
  margin-top: 20px;
}

.select-chapter {
  background-color: #141414;
  color: white;
  font-weight: bold;
  height: 100%;
  width: 100px;
  font-size: 20px;
  border: 1px solid white;
  padding-left: 10px;
  box-sizing: border-box;
}

.input-box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.input-box h3 {
  display: flex;
  flex-basis: 100%;
}

.btn-box {
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1024px) {
  .manga-content h3 {
    font-size: 16px
  }

  .manga-content li {
    font-size: 12px
  }

  .manga-content h1 {
    font-size: 18px
  }

  .manga-content h2 {
    font-size: 14px
  }


}

@media all and (max-width: 768px) {
  .select-chapter {
    width: 100px;
  }

  .cta-chapter {
    font-size: 12px;
  }

  .wrapper-manga {
    height: 100vh;
  }

  .manga-bio {
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0px !important;
    width: 100%;
    height: 100vh;
  }

  .manga-img {
    flex-basis: 100% !important;
    max-width: 100%;
    width: 100%;
  }

  .manga-img img {
    flex-basis: 100% !important;
    max-width: 100%;
    width: 100%;
    object-position: top;
    height: 100vh;
    max-height: 100%;

  }

  .manga-content {
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    padding: 20px;
  }

}

/* Page lecteur Manga */

.chapter-wrapper {
  display: block;
  width: auto;
  max-width: 100vw !important;
  box-sizing: border-box;

}

.input-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  margin: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  height: 35px;
  box-sizing: border-box;
}

.selector-wrapper {
  display: flex;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0%;
  font-weight: bold;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  height: auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px;
}

.page-wrapper p {
  flex-basis: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.page-wrapper button {
  all: unset;
  background-color: white;
  color: #141414;
  border: 1px solid #141414;
  font-size: 14px;
  padding: 5px 25px;
  transition: 0.5s;
  font-weight: bold;
}

.page-wrapper button:disabled {
  opacity: 0.35;
}

.page-wrapper button:disabled:hover {
  opacity: 0.35;
}

.page-wrapper button:hover {
  opacity: 0.75;
  cursor: pointer;
}


.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
}

.image-wrapper img {
  height: auto;
  width: 100vw;
  object-fit: contain;
  display: flex;
  margin: auto;
}

.page-input-wrapper {
  display: block;
}

.btn-page-prev,
.btn-page-next {
  all: unset;
  position: fixed;
  width: 100px;
  height: 40px;
  transition: 0.5s;
  font-size: 80px;
  justify-content: center;
  display: flex;
  padding-top: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  line-height: 0em;
}

.btn-page-next {
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
}

.btn-page-prev {
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
}

.btn-page-prev:hover,
.btn-page-next:hover {
  opacity: 0.65;
  cursor: pointer;
}

.btn-page-prev:disabled,
.btn-page-next:disabled {
  color: grey
}

.pages-nbr {
  color: white !important;
  font-weight: bold !important;
  font-size: 16px;
}

.selector-wrapper select {
  background-color: #141414;
  color: white;
  font-weight: bold;
  height: 35px;
  border: 1px solid transparent;
  margin-left: 5px;
}

.selector-wrapper select option {
  font-weight: bold;
  font-size: 16px;
}

.selector-wrapper select:focus {
  border: 0px solid transparent;
}

@media all and (min-width : 1024px) {
  .image-wrapper img {
    max-width: 1200px;
  }


}

@media all and (max-width : 1023px) {

  .btn-page-prev,
  .btn-page-next {
    position: fixed;
    width: 50px;
    height: 30px;
    transition: 0.5s;
    font-size: 40px;
    justify-content: center;
    display: flex;
    padding-top: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    line-height: 0em;
  }
}


@media all and (max-width : 768px) {

  .btn-page-prev,
  .btn-page-next {
    position: fixed;
    width: 30px;
    height: 15px;
    transition: 0.5s;
    font-size: 30px;
    justify-content: center;
    display: flex;
    padding-top: 7px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    line-height: 0em;
  }

  .btn-page-prev,
  .btn-page-next {
    all: unset;
    position: fixed;
  }

  .btn-page-prev {
    left: 0px;
    height: 100%;
    width: 50%;
    opacity: 0;
  }

  .btn-page-next {
    right: 0px;
    height: 100%;
    width: 50%;
    opacity: 0;
  }

  .btn-page-prev:hover,
  .btn-page-next:hover {
    opacity: 0;
  }
}

.back-home {
  position: fixed;
  top: 1%;
  left: 2%;
  color: white;
  z-index: 8888;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s;
}

.back-home:hover {
  opacity: 0.65;
}

.full-wrapper {
  display: flex;
  flex-direction: column;
}

.disabled {
  display: none !important;
}

.mode-verticale {
  color: white;
  position: fixed;
  z-index: 9999;
  right: 2%;
  top: 1%;
  background-color: white;
  color: #141414;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  transition: 0.5s;
}

.mode-verticale:hover {
  opacity: 0.5;
}

.mode-verticale.green {
  background-color: rgb(255, 209, 4);
  color: black
}

@media all and (max-width: 768px) {}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  flex-wrap: wrap;
  gap: 20px;
}

.header .left-box {
  display: flex;
  align-items: center;
  height: 110px
}
.header .right-box {
  height: 110px;
  display: flex;
  align-items: center;
}
.header .right-box a {
  all: unset;
  font-weight: 600;
  letter-spacing: 0.025em;
  margin-right: 20px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 6px 24px 0px, rgba(255, 255, 255, 0.2) 0px 0px 0px 1px;  padding : 10px;
}

.header h1 {
  height: 50px;
}

.search-bar {
  all: unset;
  display: flex;
  height: 35px;
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
  padding-bottom: 3px;
  background-color: #141414;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.718);
}

.search-bar::placeholder {
  color: rgba(255, 255, 255, 0.718);
}

.loading {
  width: 200px;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../public/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation-name: breathing;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  opacity: 0.2;
}

@keyframes breathing {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.4;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.4;
  }
}

.logo {
  width: 150px;
  height: 110px;
  position: relative;
  background-image: url("../public/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1
}


@media all and (max-width: 768px) {
  .header {
    gap: 10px;

  }
}

@media all and (max-width: 490px) {
.logo{
  width: 100px;
}
  .search-bar {
    width: 125px
  }
  .header .right-box a {
    font-size: 13px;
    letter-spacing: 0.03em;
  }
}
@media all and (max-width: 360px) {
  .header {}
  .header .left-box  {
    flex-basis: 100%;
    margin-top: 30px;
  }
  .search-bar {
    width: 100%;
    margin-right: 20px;
  }
  .header .right-box {
    height: 50px;
    width: 100px;
    position: absolute;
    top: 5px;
    right: 10px;

  }


}

/*temporaires */
.right-box {
  display: none !important;
}